<style lang="sass" scoped>
.title
  font-weight: bold
  // font-size: 1.5rem
  color: #555
.v-list
  li
    padding-bottom: 0.5rem
  .tag
    color: #777
    padding-left: .25rem
    font-weight: bold
    &.tag-blue
      color: royalblue
    &.tag-yellow
      color: #f53b57
  .name
    color: #333
    font-weight: 500
    border-bottom: solid 0.1rem #ccc
    &:hover
      color: #000
      font-weight: 500
      text-decoration: none
      border-bottom: solid 0.1rem #555
  .rel.bg-light.rounded.p-1.mb-1.d-inline-block

  // li.new::marker
  li.new
    &::marker
      // content: "• "
      // font-size: 1.9rem
      content: "+ "
      font-size: 1rem
      color: #ccc
      display: inline-block
      line-height: 1rem
      // padding-top: 10px
      // width: 1.3rem
      // margin-left: -1.3rem
      // position: relative
      // top: 10px
    input
      border: 0
      box-shadow: 0
.desc
  line-height: 1.5rem
.prop
  font-size: 14px
  width: 200px
  padding-bottom: 0.5rem
.select
  padding: 0.5rem 1rem
  text-align: center
  font-weight: 700
  color: #fff
  &.select-blue
    background-color: #3c40c6
  &.select-green
    background-color: #05c46b
  &.select-gray
    background-color: #808e9b
.title-tag
  font-size: 14px
  color: #777
  padding-left: .25rem
  font-weight: bold
</style>
<template lang="pug">
div.p-4
  h1 시작화면
  div.shadow.my-4.bg-light
    .container
      .d-flex
        div.my-4(style='width: 100px; font-size: 12px;')
          span.mt-2.mb-2.d-block.text-muted.opacity-50 분류
          strong.mb-2.d-block 연락처
          span.mb-2.d-block 고객메모
          span.mb-2.d-block 전화
          span.mb-2.d-block 미팅로그
        .bg-white.my-4.w-100
          .d-flex
            h1.p-4.title 연락처
            .pt-4.ml-auto.pr-4.d-flex(style='height: 60px')
              input.form-control.bg-light.shadow-none(type='search' style='width: 200px' placeholder='검색어')
              button.btn.btn-default.border.shadow-sm.ml-2(type='button' style='') 메뉴
                small.ml-2(style='font-size: 9px;'): b-icon-caret-down-fill
          ul.v-list
            li
              .d-flex
                a.name(href='#') 김지호
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 10:05
            li
              .d-flex
                a.name(href='#') 박근혜
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:43
            li
              .d-flex
                a.name(href='#') 이명박
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:33
            li.new
              input.form-control.w-50.shadow-none.p-0(type='text' placeholder='검색 또는 추가')

  h1 상태가 있는 목록
  div.shadow.my-4.bg-light
    .container
      .d-flex
        div.my-4(style='width: 100px; font-size: 12px;')
          span.mt-2.mb-2.d-block.text-muted.opacity-50 분류
          strong.mb-2.d-block 연락처
          span.mb-2.d-block 고객메모
          span.mb-2.d-block 전화
          span.mb-2.d-block 미팅로그
        .bg-white.my-4.w-100
          .d-flex
            h1.p-4.title 연락처
            .pt-4.ml-auto.pr-4.d-flex(style='height: 60px')
              input.form-control.bg-light.shadow-none(type='search' style='width: 200px' placeholder='검색어')
              button.btn.btn-default.border.shadow-sm.ml-2(type='button' style='') 메뉴
                small.ml-2(style='font-size: 9px;'): b-icon-caret-down-fill
          ul.v-list
            li
              .d-flex
                a.name(href='#') 김지호
                  span.tag #연락중
                  span.tag.tag-blue #온보딩
                span.opacity-50.text-muted.ml-3 마케팅 툴 관심가지다가 연락주심
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 10:05
            li
              .d-flex
                a.name(href='#') 박근혜
                  span.tag #연락완료
                  span.tag #수감중
                span.opacity-50.text-muted.ml-3 승마장 운영중, VIP들 DM돌려야하는데 뿌리오 이용해봤으나 불편. 연비용 200-300 생각
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:43
            li
              .d-flex
                a.name(href='#') 이명박
                  span.tag.tag-yellow #연락대기
                span.opacity-50.text-muted.ml-3 시공사관리필요, 빠른미팅 원하심
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:33
            li.new
              input.form-control.w-50.shadow-none.p-0(type='text' placeholder='검색 또는 추가')

  h1 추가하기
  div.shadow.my-4.bg-light
    .container
      .d-flex
        div.my-4(style='width: 100px; font-size: 12px;')
          span.mt-2.mb-2.d-block.text-muted.opacity-50 분류
          strong.mb-2.d-block 연락처
          span.mb-2.d-block 고객메모
          span.mb-2.d-block 전화
          span.mb-2.d-block 미팅로그
        .bg-white.my-4.w-100
          .d-flex
            h1.p-4.title 연락처
            .pt-4.ml-auto.pr-4.d-flex(style='height: 60px')
              input.form-control.bg-light.shadow-none(type='search' style='width: 200px' placeholder='검색어')
              button.btn.btn-default.border.shadow-sm.ml-2(type='button' style='') 메뉴
                small.ml-2(style='font-size: 9px;'): b-icon-caret-down-fill
          ul.v-list
            li
              .d-flex
                a.name(href='#') 김지호
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 10:05
            li
              .d-flex
                a.name(href='#') 박근혜
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:43
            li
              .d-flex
                a.name(href='#') 이명박
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:33
            li.new
              input.form-control.w-50.shadow-none.p-0(type='text' placeholder='검색 또는 추가' value='이동건')
              div(style='position: relative')
                div.v-dropdown.bg-white.shadow.rounded(style='position: absolute; top: 0px; width: 300px; height: 300px')
                  .p-1
                    small.text-muted 새로 추가
                  .p-2.bg-light
                    strong "이동건"
                    //- span.text-muted.px-1.opacity-50 /
                    span.ml-1 추가하기
                      small.float-right.text-muted.opacity-50 Enter 엔터키
                  .p-1
                    small.text-muted 검색결과
                    //- .border-bottom


  h1(style='margin-top: 300px') 참조하여 추가하기
  div.shadow.my-4.bg-light
    .container
      .d-flex
        div.my-4(style='width: 100px; font-size: 12px;')
          span.mt-2.mb-2.d-block.text-muted.opacity-50 분류
          strong.mb-2.d-block 연락처
          span.mb-2.d-block 고객메모
          span.mb-2.d-block 전화
          span.mb-2.d-block 미팅로그
        .bg-white.my-4.w-100
          .d-flex
            h1.p-4.title 연락처
            .pt-4.ml-auto.pr-4.d-flex(style='height: 60px')
              input.form-control.bg-light.shadow-none(type='search' style='width: 200px' placeholder='검색어')
              button.btn.btn-default.border.shadow-sm.ml-2(type='button' style='') 메뉴
                small.ml-2(style='font-size: 9px;'): b-icon-caret-down-fill
          ul.v-list
            li
              .d-flex
                a.name(href='#') 김지호
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 10:05
            li
              .d-flex
                a.name(href='#') 박근혜
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:43
            li
              .d-flex
                a.name(href='#') 이명박
                span.opacity-50.text-muted.ml-auto.pr-4 08-03 오전 09:33
            li.new
              input.form-control.w-50.shadow-none.p-0(type='text' placeholder='검색 또는 추가' value='이동건')
              small.rel.bg-light.rounded.p-1.mb-1.d-inline-block.mr-2
                span 미팅로그
                span.text-muted.px-1.opacity-50 /
                span 2021-08-01 고량주 모임
              small.rel.bg-light.rounded.p-1.mb-1.d-inline-block.mr-2
                span 투자자
                span.text-muted.px-1.opacity-50 /
                span 나눔엔젤스 NanUhm Angels Investment
              div(style='position: relative')
                div.v-dropdown.bg-white.shadow.rounded(style='position: absolute; top: 0px; width: 300px; height: 300px')
                  .p-1
                    small.text-muted 새로 추가
                  .p-2
                    strong "이동건"
                    //- span.text-muted.px-1.opacity-50 /
                    span.ml-1 추가하기
                  .p-1
                    small.text-muted 검색결과
                    //- .border-bottom
                  .p-2.bg-light
                    span 연락처(2020)
                    span.text-muted.px-1.opacity-50 /
                    span 나눔엔젤스 이남희 팀장님
                    small.d-block "네오플라이 나눔 이동건 이사님"
                  .p-2
                    span 미팅로그
                    span.text-muted.px-1.opacity-50 /
                    span 2021-03-15 문정 식사
                    small.d-block "이남희(이동건이사님) 식사약속"


  h1(style='margin-top: 300px') 테이블 보기옵션
  p 노이텔러 테이블 그대로 옮겨오기. 한눈에 촥 폁쳐보는. 대신에 필드타입은 여기 안으로 제한 (Text, Number, Select)
  h1(style='margin-top: 300px') 조회하기
  div.shadow.my-4.bg-light
    .container
      .d-flex
        div.my-4(style='width: 100px; font-size: 12px;')
          span.mt-2.mb-2.d-block.text-muted.opacity-50 분류
          strong.mb-2.d-block 연락처
          span.mb-2.d-block 고객메모
          span.mb-2.d-block 전화
          span.mb-2.d-block 미팅로그
        .bg-white.my-4.w-100
          .d-flex
            h1.p-4.title 이동건
              span.title-tag.ml-3 #미팅잡힘

            .pt-4.ml-auto.pr-4.d-flex(style='height: 60px')
              //- input.form-control.bg-light.shadow-none(type='search' style='width: 200px' placeholder='검색어')
              button.btn.btn-default.border.text-primary.shadow-sm.ml-2(type='button' style='') 수정
              button.btn.btn-default.border.shadow-sm.ml-2(type='button' style='') 메뉴
                small.ml-2(style='font-size: 9px;'): b-icon-caret-down-fill
          .d-flex
            div.desc.px-4.w-100
              p.mb-2 2019~2020년 투자 건수 : 9건
                br
                | 벤처 펀드 투자 경력 : 80건+, 1,080억원+
              p.mb-2(style='white-space: pre-line')
                | 수퍼브 (모바일 콘텐츠 /피아니스타) 빅히트에 인수
                br
                | 겜프스 (모바일 콘텐츠 / 브라운더스트) 네오위즈에 인수
                br
                | 탱그램팩토리(헬스케어 / IoT줄넘기) 카카오/이상네트웍스에 인수
                br
                | 슈가힐 (부동산 / 상가사무실O2O) 직방에 인수
                br
                | 엔드림 (모바일콘텐츠 / 조이시티) 조이시티 MBO 인수
              ul.pl-4
                li 나눔엔젤스
                li 초기투자
                li 문정
            div(style='width: 300px')
              .d-flex.flex-wrap.flex-fill
                div.prop
                  strong.d-block 업체
                  span 나눔엔젤스
                div.prop
                  strong.d-block 분류
                  span.select.select-blue.d-block 투자
                div.prop
                  strong.d-block 가능성
                  span.select.select-green.d-block 높음
                div.prop
                  strong.d-block 규모
                  span.select.select-gray.d-block 중
                div.prop
                  strong.d-block 연락처1/이름
                  span 이동건
                div.prop
                  strong.d-block 연락처1/직함
                  span 엔젤
                div.prop
                  strong.d-block 연락처1/전화번호
                  span 010-8000-2000
                div.prop
                  strong.d-block 연락처2/이름
                  span 프론트
                div.prop
                  strong.d-block 연락처2/직함
                  span 담당자님
                div.prop
                  strong.d-block 연락처2/전화번호
                  span 02-0000-0000
                div.prop
                  strong.d-block Lead Network
                    small.float-right
                      b-icon-question-circle-fill
                  .bg-light.p-2.rounded.text-center.text-muted 내용 가져오는 중
          //- .border-bottom.opacity-50
          .bg-light(style='height: 0.5rem')
          .p-4
            .text-center
              //- .btn-group
              button.btn.btn-default.btn(type='button')
                span.border-bottom.border-dark.pb-1 분류별
              button.btn.btn-default.btn(type='button') 시간순
            h5.title 고객메모
            ul.v-list.pl-4.mb-0
              li
                .d-flex
                  a.name(href='#') 카톡연락
                  span.opacity-50.text-muted.ml-3 해피톡 내, 비상사태 지속중. 8월 2일 월요일 오후에 재확인 필요
                  span.opacity-50.text-muted.ml-auto.pr-4 김지호 2021.07.26 (월) 20:47
              li
                .d-flex
                  a.name(href='#') 만나서 차한잔
                  span.opacity-50.text-muted.ml-3 지금은 어찌되었을지 모르겠지만, 과거 기준에서는 어드민에 표 데이터를 복사해서 구글시트에 붙였음.
                  span.opacity-50.text-muted.ml-auto.pr-4 김학범 2021.07.11 (일) 12:53
              li.new
                input.form-control.w-50.shadow-none.p-0(type='text' placeholder='검색 또는 추가')
            .border-bottom.opacity-50.mb-3
            h5.title 미팅로그
            ul.v-list.pl-4.mb-0
              li
                .d-flex
                  a.name(href='#') DD 3차 (준비중)
                  //- span.opacity-50.text-muted.ml-3 해피톡 내, 비상사태 지속중. 8월 2일 월요일 오후에 재확인 필요
                  span.opacity-50.text-muted.ml-auto.pr-4 김지호 2021.08.15 (월) 20:47
                  button.btn.btn-default.btn-sm.opacity-50(type='button' style='margin-top: -5px') 삭제
              li
                .d-flex
                  a.name(href='#') DD 2차
                  //- span.opacity-50.text-muted.ml-3 해피톡 내, 비상사태 지속중. 8월 2일 월요일 오후에 재확인 필요
                  span.opacity-50.text-muted.ml-auto.pr-4 김지호 2021.07.26 (월) 20:47
                  button.btn.btn-default.btn-sm.opacity-50(type='button' style='margin-top: -5px') 삭제
              li
                .d-flex
                  a.name(href='#') Due Diligence 1차
                  span.opacity-50.text-muted.ml-3 참석자: A, B, C
                  span.opacity-50.text-muted.ml-auto.pr-4 김호지 2021.07.11 (일) 12:53
                  button.btn.btn-default.btn-sm.opacity-50(type='button' style='margin-top: -5px') 삭제
              li.new
                input.form-control.w-50.shadow-none.p-0(type='text' placeholder='검색 또는 추가')
            .border-bottom.opacity-50.mb-3
            //- h5.title 미팅로그
            ul.v-list.pl-1.mb-0
              li.new
                input.form-control.w-50.shadow-none.p-0(type='text' placeholder='분류 추가' style='font-size: 16px; font-weight: 600;')




  h1 수정하기
  div.shadow.my-4.bg-light
    .container
      .d-flex
        div.my-4(style='width: 100px; font-size: 12px;')
          span.mt-2.mb-2.d-block.text-muted.opacity-50 분류
          strong.mb-2.d-block 연락처
          span.mb-2.d-block 고객메모
          span.mb-2.d-block 전화
          span.mb-2.d-block 미팅로그
        .bg-white.my-4.w-100
          .d-flex
            h1.p-4.title 이동건
              span.title-tag.ml-3 #미팅잡힘

            .pt-4.ml-auto.pr-4.d-flex(style='height: 60px')
              //- input.form-control.bg-light.shadow-none(type='search' style='width: 200px' placeholder='검색어')
              button.btn.btn-default.border.text-secondary.shadow-sm.ml-2(type='button' style='') 임시 저장
              button.btn.btn-default.border.text-primary.shadow-sm.ml-2(type='button' style='') 수정완료
              button.btn.btn-default.border.shadow-sm.ml-2(type='button' style='' disabled) 메뉴
                small.ml-2(style='font-size: 9px;'): b-icon-caret-down-fill
          .d-flex
            div.desc.px-4.w-100
              tiptap
              //- textarea.form.
                p.mb-2 2019~2020년 투자 건수 : 9건
                  br
                  | 벤처 펀드 투자 경력 : 80건+, 1,080억원+
                p.mb-2(style='white-space: pre-line')
                  | 수퍼브 (모바일 콘텐츠 /피아니스타) 빅히트에 인수
                  br
                  | 겜프스 (모바일 콘텐츠 / 브라운더스트) 네오위즈에 인수
                  br
                  | 탱그램팩토리(헬스케어 / IoT줄넘기) 카카오/이상네트웍스에 인수
                  br
                  | 슈가힐 (부동산 / 상가사무실O2O) 직방에 인수
                  br
                  | 엔드림 (모바일콘텐츠 / 조이시티) 조이시티 MBO 인수
                ul.pl-4
                  li 나눔엔젤스
                  li 초기투자
                  li 문정
            div(style='width: 300px')
              .d-flex.flex-wrap.flex-fill
                div.prop
                  strong.d-block 업체
                  input.form-control(type='text' value='나눔엔젤스')
                div.prop
                  strong.d-block 분류
                  span.select.select-blue.d-block 투자
                div.prop
                  strong.d-block 가능성
                  span.select.select-green.d-block 높음
                div.prop
                  strong.d-block 규모
                  span.select.select-gray.d-block 중
                div.prop
                  strong.d-block 연락처1/이름
                  input.form-control(type='text' value='이동건')
                div.prop
                  strong.d-block 연락처1/직함
                  input.form-control(type='text' value='엔젤')
                div.prop
                  strong.d-block 연락처1/전화번호
                  input.form-control(type='text' value='010-8000-2000')
                div.prop
                  strong.d-block 연락처2/이름
                  input.form-control(type='text' value='프론트')
                div.prop
                  strong.d-block 연락처2/직함
                  input.form-control(type='text' value='담당자님')
                div.prop
                  strong.d-block 연락처2/전화번호
                  input.form-control(type='text' value='02-0000-0000')
                div.prop
                  strong.d-block Lead Network
                    small.float-right
                      b-icon-question-circle-fill
                  button.btn.btn-light.text-primary(type='button') ON
                  //- .bg-light.p-2.rounded.text-center.text-muted 내용 가져오는 중


  h1 미팅로그 빠른 추가하기 ⭐⭐⭐
  p (메모부터 추가하고 연락처 연결하기)

  h1 빠른검색 ⭐⭐
  p (draft 포함) search bar (filter input group) 추가

  h1 보고있는 사람 실시간 표시 ⭐⭐
  p (notion person stacked bubble)

  h1 엑셀 가져오기 ⭐⭐
  p 복붙, 파일선택하여 파싱(로컬), 쿼리가져오기도 버튼은 파놓고 고객이 어떤 타입(mysql, pgsql, mssql) 필요한지 집계해야할덧

  h1 연락처 동기화 ⭐⭐⭐
  p Google Contacts API 항목이랑 매칭

  h1 공유하기 ⭐
  p 보고있는 목록 복사해서 붙여넣기했을때 메일이나 워드로 table 표시되는지?
  p 공유링크발급 (모바일 필요)

  h1 파이프라인 ⭐
  p 개념이 좀 바뀜 (#해시태그 기반으로)

  h1 리드 네트워크 관리자 화면 ⭐
  p (서비스단 request, 백단 어드민에서 reply - 조회때는 데이터를 하드카피하지않고 quote처럼 표시만 해주자.  주소는 지도가 보여야함-네이버 카카오맵 잘되나? / Lead Type 지정하고 재사용해야함)

  h1 리마인더 화면 ⭐⭐
  p 날짜추가 (생일, 결혼기념일 repeat year, 한줄메모+날짜(범위)   리스트로 쭉 모아봄 (facebook timeline처럼) range는 start, end에만 표시,  check done 표시 (아예 가리지는 않고, 체크여부만 → 날짜보고 액션했으면 해당고객에게 메모추가 또는 로그남기기)  내일 오전에 알림받기, 3일뒤 오전에 알림받기 (그때로 날짜 추가)
  p 고객조회 > 메뉴 > 내일/3일뒤/다음주 월요일 다시알림

  h1 workspace log plateau ⭐⭐
  p 시간순으로 모든내역 조회 (pc에서는 왼쪽에 리스트 작게표시, mobile에서는 상단바 caret 아래위 토글되면서 전체 메뉴 표시  OR  tab bar로 표시?  OR tabbar에는 상태별로 표시? )
  p 시간이 없으므로 .. 일단 전체내역부터 보게하고
  p A업체 추가 7일전  (activity/timeline보다는 inbox 느낌으로가면 어떨까? 오른쪽에 내용확인) mark as read (F/U tool)

  h1 조직(업체)를 Text로 쓰다가, 분류로 빼는 경우 ⭐⭐⭐
  p 자연스럽게 계층을 나누는 경우 (문제는 없지만 항목값에 @Page 필요)
  p 연락처1,2 통으로 쓰다가 나누는경우도

  h1 모바일 시작화면 ⭐⭐⭐
  p (상단에 분류선택)

  h1 모바일 수정하기 ⭐
  p (하단 full fixed button)

  h1 모바일 조회하기 ⭐⭐⭐
  p (property, description, relations, changelog-페이지전환)

  h1 모바일 검색하기 ⭐⭐⭐
  p (추가화면과 동일한 레이아웃)

  h1 모바일 테이블 표시 ⭐
  p (fixed header)


</template>

<script>

import Tiptap from '@/components/Tiptap'

export default {
  name: 'sample',
  components: {
    Tiptap,
  },
  data() {
    return {
      done: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.done = true
    }, 300);
  }
}
</script>
